// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Pulse from "../codeFile/Pulse.tsx";
const PulseFonts = getFonts(Pulse);

const serializationHash = "framer-oj5pz"

const variantClassNames = {HMfEaYskW: "framer-v-19xi14q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, WV_gMsgQ0: click ?? props.WV_gMsgQ0} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WV_gMsgQ0, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "HMfEaYskW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap27wkt1 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (WV_gMsgQ0) {const res = await WV_gMsgQ0(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19xi14q", className, classNames)} data-border data-framer-name={"Play Button "} data-highlight layoutDependency={layoutDependency} layoutId={"HMfEaYskW"} onTap={onTap27wkt1} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(33, 33, 33, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, ...style}}><ComponentViewportProvider ><motion.div className={"framer-1l9183x-container"} layoutDependency={layoutDependency} layoutId={"JddvcUeam-container"}><Pulse borderWidth={2} color={"var(--token-4e04ecb2-4c8c-4232-853c-c5a9a14468f6, rgb(113, 93, 227))"} delay={0.6} direction={"out"} height={"100%"} id={"JddvcUeam"} layoutId={"JddvcUeam"} pulseOpacity={0.5} pulseSize={18} radiusType={"full"} radiusValue={20} style={"solid"} transition={{damping: 60, delay: 0, mass: 1, stiffness: 150, type: "spring"}} width={"100%"}/></motion.div></ComponentViewportProvider><SVG className={"framer-5lswxf"} data-framer-name={"graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"hhJ96a6c6"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 32 32\"><path d=\"M 6 7.537 C 6 5.636 8.039 4.431 9.705 5.347 L 25.092 13.811 C 26.819 14.76 26.819 17.241 25.092 18.191 L 9.707 26.655 C 8.04 27.57 6 26.365 6 24.464 Z\" fill=\"var(--token-0fb2a20a-b4d3-45b7-8fb2-3c30d2ac4f07, rgb(255, 255, 255))\"></path></svg>"} svgContentId={8870311318} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oj5pz.framer-zkical, .framer-oj5pz .framer-zkical { display: block; }", ".framer-oj5pz.framer-19xi14q { cursor: pointer; height: 64px; overflow: visible; position: relative; width: 64px; }", ".framer-oj5pz .framer-1l9183x-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-oj5pz .framer-5lswxf { flex: none; height: 32px; left: calc(53.12500000000002% - 32px / 2); position: absolute; top: calc(50.00000000000002% - 32px / 2); width: 32px; }", ".framer-oj5pz[data-border=\"true\"]::after, .framer-oj5pz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WV_gMsgQ0":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJaUVZAX4K: React.ComponentType<Props> = withCSS(Component, css, "framer-oj5pz") as typeof Component;
export default FramerJaUVZAX4K;

FramerJaUVZAX4K.displayName = "Elements/Play Button";

FramerJaUVZAX4K.defaultProps = {height: 64, width: 64};

addPropertyControls(FramerJaUVZAX4K, {WV_gMsgQ0: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerJaUVZAX4K, [{explicitInter: true, fonts: []}, ...PulseFonts], {supportsExplicitInterCodegen: true})